import React, { useState, useEffect } from "react";
import "./Carousel.css";
import Mujeres1 from "./media/mujeres-1.webp";
import Mujeres2 from "./media/mujeres-2.webp";
import Mujeres3 from "./media/mujeres-3.webp";

const images = [Mujeres3, Mujeres2, Mujeres1];

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const delay = 3000; // Tiempo en milisegundos (3 segundos)

  // Función para avanzar al siguiente slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Función para retroceder al slide anterior
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Efecto para el desplazamiento automático
  useEffect(() => {
    const interval = setInterval(nextSlide, delay);
    return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
  }, []);

  return (
    <div className="carousel">
      <button className="carousel-button prev" onClick={prevSlide}>
        &#10094;
      </button>
      <div className="carousel-images">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-image ${
              index === currentIndex ? "active" : ""
            }`}
          >
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      <button className="carousel-button next" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
}

export default Carousel;
