import React from "react";
import logo from "./media/logo.png";
import redes1 from "./media/redes-1.png";
import redes2 from "./media/redes-2.png";
import redes4 from "./media/redes-4.png";
import redes5 from "./media/redes-5.png";

export default function Sigueme() {
  return (
    <div id="sigueme" className="sigueme centrado-todo">
      <img src={logo} className="logo-footer " alt="logo" />
      <h3 className="centrar-texto parrafo-sigueme no-responsive">
        Transformemos juntos{" "}
        <span className="resaltado">nuestra provincia</span>,
        <br />
        porque el verdadero cambio nace del
        <br />
        compromiso,{" "}
        <span className="resaltado">la educación y el trabajo colectivo</span>.
      </h3>
      
      <h3 className="centrar-texto parrafo-sigueme responsive">
        Transformemos juntos{" "}
        <span className="resaltado">nuestra provincia</span>,
        porque el verdadero cambio nace del
        compromiso,{" "}
        <span className="resaltado">la educación y el trabajo colectivo</span>.
      </h3>

      <div className="contenedor-redes-footer">
        <a
          href="https://www.facebook.com/christianmelendezec"
          rel="noreferrer"
          target="_blank"
        >
          <img src={redes1} className="redes-footer" alt="redes" />
        </a>
        <a
          href="https://www.instagram.com/christian.melendez.mvp/"
          rel="noreferrer"
          target="_blank"
        >
          <img src={redes2} className="redes-footer" alt="redes" />
        </a>
        <a
          href="https://www.tiktok.com/@christian.melendez.g"
          rel="noreferrer"
          target="_blank"
        >
          <img src={redes4} className="redes-footer" alt="redes" />
        </a>
        <a
          href="https://x.com/chrismel1606"
          rel="noreferrer"
          target="_blank"
        >
          <img src={redes5} className="redes-footer" alt="redes" />
        </a>
      </div>
    </div> //fin
  );
}
