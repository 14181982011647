import "./App.css";
import Header from "./header.jsx";
import Banner from "./Banner.jsx";
import Formacion from "./Formacion.jsx";
import Compromiso from "./Compromiso.jsx";
import Empresas from "./empresas.jsx";
import Sigueme from "./Sigueme.jsx";

function App() {
  return (
    <div className="principal">
      <Header />
      <Banner />
      <Formacion />
      <Compromiso />
      <Empresas />
      <Sigueme />
    </div>
  );
}

export default App;
