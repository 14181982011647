import React from "react";
import fotoempresas from "./media/empresas.webp";

export default function empresas() {
  return (
    <div id="empresas" className="empresas">
      <div className="formacion-uno">
        <h2 className="nombre">Empresas y Emprendimientos</h2>
        <p className="presentacion">
          A lo largo de mi carrera, he liderado la creación de más de 15 marcas{" "}
          <br />
          reconocidas que han logrado destacarse en mercados competitivos.{" "}
          <br />
          Mi filosofía empresarial conecta innovación con necesidades del
          mercado
        </p>
      </div>

      <div className="empresas-dos">
        <div className="cuarenta">
          <h2>Logros Empresariales</h2>
          <p className="parrafo-empresas">
            Implementé un modelo de
            <br />
            marketing institucional que triplicó la
            <br />
            matrícula del ISTE en solo dos años.
          </p>

          <p className="parrafo-empresas">
            Diseñé estrategias de crecimientobr
            <br />
            para diversas empresas,
            <br />
            posicionándolas como referentes en
            <br />
            sus respectivas industrias.
          </p>
        </div>

        <div className="cuarenta">
          <h2>Empresas Fundadas</h2>
          <p className="parrafo-empresas lista">iCell Academy</p>
          <p className="parrafo-empresas lista">Importadora iCell</p>
          <p className="parrafo-empresas lista">
            Punto iPhone (Quito y Ambato)
          </p>
          <p className="parrafo-empresas lista">Grupo Nexus</p>
          <p className="parrafo-empresas lista">IMarketing Ecuador</p>
        </div>
      </div>
      <div className="centrar">
        <img src={fotoempresas} className="img-empresas" alt="fotoempresas" />
      </div>
    </div> //fin
  );
}
