import React from "react";
import foto from "./media/foto-principal.webp";

export default function Banner() {
  return (
    <div id="sobre" className="banner">
      <div className="banner-izq">
        <p className="saludo">¡Hola!</p>
        <h1 className="nombre">
          Soy Christian <span className="resaltado">Meléndez</span>
        </h1>
        <h2 className="subtitulo">
          Candidato a la Asamblea Nacional por Tungurahua
        </h2>
        <p className="presentacion no-responsive">
          Ambateño comprometido con la educación, el
          <br />
          desarrollo sostenible y la innovación.
          <br />
          Trabajo por un <b>Tungurahua de oportunidades</b>,<br />
          donde la educación sea el motor del progreso
        </p>
        <p className="presentacion responsive">
          Ambateño comprometido con la educación, el desarrollo sostenible y la 
          innovación. Trabajo por un <b>Tungurahua de oportunidades</b>, donde 
          la educación sea el motor del progreso
        </p>
      </div>
      <div className="banner-der">
        <img src={foto} className="logo-img" alt="foto" />
      </div>
    </div>
  );
}
