import React from "react";
import fotodos from "./media/segunda.webp";

export default function Formacion() {
  return (
    <div id="formacion" className="formacion">
      <div className="formacion-uno espacio-abajo">
        <h2 className="nombre">Formación Académica</h2>
        <p className="presentacion no-responsive">
          Conoce en lo que me he preparado para aportar al
          <br />
          desarrollo de nuestra comunidad
        </p>
        <p className="presentacion responsive">
          Conoce en lo que me he preparado para aportar al 
          desarrollo de nuestra comunidad
        </p>

        <div className="formacion-dentro">
          <div className="cincuenta fondo">
            <p className="subtitulo">
              <b>Ingeniero en Diseño Industrial</b>
            </p>
            <p>Pontificia Universidad Católica del Ecuador.</p>
          </div>
          <div className="cincuenta fondo">
            <p className="subtitulo">
              <b>
                Magíster en Evaluación de
                <br />
                Instituciones de Educación Superior
              </b>
            </p>
            <p>Universidad de La Rioja, España.</p>
          </div>
        </div>

        <div className="formacion-dentro">
          <div className="cincuenta fondo">
            <p className="subtitulo">
              <b>Magíster en Marketing Digital</b>
            </p>
            <p>
              Universidad Particular de Especialidades Espíritu Santo, Ecuador.
            </p>
          </div>
          <div className="cincuenta fondo">
            <p className="subtitulo">
              <b>
                Especializaciones en comunicación organizacional, producción, y
                desarrollo sostenible.
              </b>
            </p>
            <p>Universidad La Sabana, Colombia.</p>
          </div>
        </div>
      </div>

      <div className="formacion-uno">
        <div className="formacion-segunda-seccion">
          <div className="sesenta">
            <h2 className="subtitulo-izquierda resaltado espacio-arriba">
              Experiencia Profesional
            </h2>
          </div>
          <div className="treinta"></div>
        </div>
        <div className="formacion-segunda-seccion">
          <div className="sesenta">
            <p className="subtitulo border-arriba espacio-arriba">
              <b>Docente y Director de Marketing</b>
            </p>
            <p className="espacio-abajo">
            Tecnológico Superior Universitario España.
            </p>

            <p className="subtitulo border-arriba espacio-arriba">
              <b>Vicerrector Administrativo
              </b>
            </p>
            <p className="espacio-abajo">
            Tecnológico Superior Universitario España.
            </p>

            <p className="subtitulo border-arriba espacio-arriba espacio-abajo">
              <b>CEO y Fundador de IMarketing</b>
            </p>

            <p className="subtitulo border-arriba espacio-arriba ">
              <b>Director Administrativo</b>
            </p>

            <p className="espacio-abajo">De la Unidad Educativa Británico.</p>

            <p className="subtitulo border-arriba espacio-arriba espacio-abajo border-abajo">
              <b>Gerente y propietario de Corporación M&G</b>
            </p>
          </div>

          <div className="treinta">
            <img src={fotodos} className="logo-img" alt="foto" />
          </div>
        </div>
      </div>
    </div>
  );
}
