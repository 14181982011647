import React from "react";
import sumando from "./media/sumando.webp";
import Carrusel from "./Carrusel.jsx";
import Carruseldos from "./Carruseldos.jsx";
import Carruseltres from "./Carruseltres.jsx";
import Carruselcuatro from "./Carruselcuatro.jsx";

export default function Compromiso() {
  return (
    <div id="compromiso" className="compromiso">
      <div className="formacion-uno">
        <h2 className="nombre">Compromiso Social y Político</h2>
        <p className="presentacion no-responsive">
          Con el desarrollo social y educativo se refleja en cada proyecto
          <br />
          que he liderado. Durante los últimos años
        </p>
        <p className="presentacion responsive">
          Con el desarrollo social y educativo se refleja en cada proyecto 
          que he liderado. Durante los últimos años
        </p>
      </div>
      <div className="compromiso-tercio">
        <div className="tercio">
          <div className="tercio-fondo">
            <p className="subtitulo espacio-arriba centrar-texto">
              <b>Mejorar la calidad educativa</b>
            </p>
            <p className="espacio-abajo centrar-texto">
              Del Tecnológico Superior Universitario España (ISTE), triplicando
              su matrícula estudiantil y fortaleciendo su reputación.
            </p>
          </div>
        </div>

        <div className="tercio">
          <div className="tercio-fondo">
            <p className="subtitulo espacio-arriba centrar-texto">
              <b>Implementar programas de inclusión educativa</b>
            </p>
            <p className="espacio-abajo centrar-texto">
              Que aseguren acceso a formación de calidad para todos.
            </p>
          </div>
        </div>

        <div className="tercio">
          <div className="tercio-fondo">
            <p className="subtitulo espacio-arriba centrar-texto">
              <b>Crear alianzas estratégicas</b>
            </p>
            <p className="espacio-abajo centrar-texto">
              entre sectores públicos y privados para generar oportunidades de
              crecimiento.
            </p>
          </div>
        </div>
      </div>

      <div className="formacion-uno centrar-texto">
        <img
          src={sumando}
          className="logo-img img-treinta espacio-abajo"
          alt="foto"
        />
      </div>

      <div className="compromiso-dos espacio-arriba  espacio-abajo invertir-responsive">
        <div className="cincuenta ">
          <Carrusel />
        </div>

        <div className="cincuenta centrado-todo  ">
          <p className="subtitulo espacio-arriba derecha-texto resaltado subtitulo-grande">
            <b>Becas para la Educación de Mujeres</b>
          </p>
          <p className="espacio-abajo derecha-texto parrafo-compromiso">
            Se entregan becas 100% cubiertas junto al ISTE para que más mujeres
            accedan al tercer nivel de educación.
          </p>
        </div>
      </div>

      <div className="compromiso-dos espacio-arriba espacio-abajo">
        <div className="cincuenta centrado-todo  ">
          <p className="subtitulo espacio-arriba izquierda-texto resaltado subtitulo-grande">
            <b>Visitas y ayuda a Comunidades en Tungurahua</b>
          </p>
          <p className="espacio-abajo izquierda-texto parrafo-compromiso">
            Se visitaron lugares como Cunchibamba para entregar agua, ya que,
            durante más de seis meses al año, no llega agua a sus hogares.
          </p>
        </div>

        <div className="cincuenta ">
          <Carruseldos />
        </div>
      </div>

      <div className="compromiso-dos espacio-arriba  espacio-abajo invertir-responsive">
        <div className="cincuenta ">
          <Carruseltres />
        </div>

        <div className="cincuenta centrado-todo  ">
          <p className="subtitulo espacio-arriba derecha-texto resaltado subtitulo-grande">
            <b>
              Jornadas Médicas
              Gratuitas
            </b>
          </p>
          <p className="espacio-abajo derecha-texto parrafo-compromiso">
            Se realizan jornadas gratuitas con
            rehabilitación, medicina general y kits
            de desparasitación.
          </p>
        </div>
      </div>

      <div className="compromiso-dos espacio-arriba espacio-abajo">
        <div className="cincuenta centrado-todo  ">
          <p className="subtitulo espacio-arriba izquierda-texto resaltado subtitulo-grande">
            <b>
              Impulso al Turismo y
              la Cultura
            </b>
          </p>
          <p className="espacio-abajo izquierda-texto parrafo-compromiso">
            Se destacan los atractivos turísticos y
            culturales del cantón con visitas de
            figuras políticas.
          </p>
        </div>

        <div className="cincuenta ">
          <Carruselcuatro />
        </div>
      </div>
    </div>
  );
}
