import React, { useState } from "react";
import "./Menu.css"; // Asegúrate de tener un archivo CSS básico
import imagenmenu from "./media/sigueme.png";


const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Función para alternar el menú
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Función para cerrar el menú
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="menu">
      <div className="menu-container">
        {/* Botón de hamburguesa */}
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>

        {/* Enlaces del menú */}
        <ul className={`menu-links ${isOpen ? "open" : ""}`}>
          <li  onClick={closeMenu}>
            <a href="#sobre">SOBRE MÍ</a> 
          </li>
          <li  onClick={closeMenu}>
            <a href="#formacion">FORMACIÓN Y<br/>EXPERIENCIA</a> 
          </li>
          <li  onClick={closeMenu}>
            <a href="#compromiso">COMPROMISO SOCIAL<br/>Y POLÍTICO</a> 
          </li>
          <li  onClick={closeMenu}>
            <a href="#empresas">EMPRESAS Y<br/>EMPRENDIMIENTOS</a> 
          </li>
          <li  onClick={closeMenu}>
            <a className="menu-resaltado" href="#sigueme"> <img src={imagenmenu} className="imagenmenu" alt="imagenmenu" /> SÍGUEME</a> 
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Menu;
