import React from "react";
import logo from "./media/logo.png";
import Menunu from "./Menu.jsx";

export default function header() {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} className="logo-img" alt="logo" />
      </div>
      <div className="menu-contenedor">
      <Menunu />
      </div>
    </header>
  );
}
